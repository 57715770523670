

































































































import {Component, Vue} from 'vue-property-decorator';
import {resolveError} from '@/utils/notifications';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import DatePicker from '@/components/DatePicker.vue';
import {lastDayOfMonth} from '@/utils/dateTime';
import IMarginFilters from '@/types/IMarginFilters';
import MarginTable from '@/components/Margin/MarginTable.vue';
import MarginService from '@/services/MarginService';
import IMarginRow from '@/types/IMarginRow';
import ICurrencyDTO from '@/types/ICurrencyDTO';
import CollapseMultiSelect from '@/components/CollapseMultiSelect.vue';
import {IAnalysisMarketplace} from '@/types/IAnalysisMarketplace';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'Margin',
  components: {
    PageWrap,
    MarginTable,
    DatePicker,
    CollapseMultiSelect
  },
})
export default class Margin extends Vue {
  now = new Date();
  form: IMarginFilters = {
    dateFrom: new Date(this.now.getFullYear(), 1, 1).toISOString().substr(0, 7),
    dateTo: new Date(this.now.getFullYear(), this.now.getMonth(), 1).toISOString().substr(0, 7),
    currency: '',
    marketplaceId: [],
  };
  margins: IMarginRow[] = [];
  currencies: ICurrencyDTO[] = [];
  marketplaces: IAnalysisMarketplace[] = [];
  loading: boolean = false;
  loadingExcel: boolean = false;
  loadingPdf: boolean = false;

  get isFormFilled(): boolean {
    return !!(this.form.dateFrom && this.form.dateTo && this.form.currency && this.form.marketplaceId.length > 0);
  }

  mounted() {
    this.getSavedFilters()
    this.fetchForm();
  }

  getSavedFilters() {
    const {filters} = getSavedPageData()
    if (filters) {
      this.form = {...filters}
    }
  }

  async fetchForm() {
    try {
      const form = await MarginService.getForm();
      this.currencies = form.currencies;
      this.marketplaces = form.marketplaces;

      const marketplacesIds = this.marketplaces.map((item: any) => item.id);
      const currency = this.currencies.find((o: ICurrencyDTO) => o.companyDefault)?.isoCode;
      if (!!currency && !this.form.currency){
        this.form.currency = currency;
      }
      this.form.marketplaceId = marketplacesIds;
      if (this.isFormFilled){
        await this.fetchData();
      }
    } catch (e) {
      resolveError(e, 'fetch_data');
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      const form = this.getForm();
      this.margins = await MarginService.getAll(form);
      savePageData({
        filters: this.form
      })
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  async downloadExcel() {
    try {
      this.loadingExcel = true;
      const form = this.getForm();
      await MarginService.downloadExcel(form);
    } catch (e) {
      resolveError(e, 'download');
    } finally {
      this.loadingExcel = false;
    }
  }

  async downloadPdf() {
    try {
      this.loadingPdf = true;
      const form = this.getForm();
      await MarginService.downloadPdf(form);
    } catch (e) {
      resolveError(e, 'download');
    } finally {
      this.loadingPdf = false;
    }
  }

  getForm() {
    return {
      dateFrom: this.form.dateFrom.concat('-').concat('01'),
      dateTo: this.form.dateTo.concat('-').concat(lastDayOfMonth(this.form.dateTo).toString()),
      currency: this.form.currency,
      marketplaceId: this.form.marketplaceId
    }
  }

}
