import axios from 'axios';
import IMarginFilters from '@/types/IMarginFilters';

export default class MarginRepository {

    static getForm() {
        return axios.get(`margin/forms`);
    }

    static getAll(obj: IMarginFilters) {
        return axios.post('margin/all/', obj);
    }

    static async downloadExcel(form: object) {
        return axios.post('margin/all/excel', form, {responseType: 'blob'});
    }

    static async downloadPdf(form: object) {
        return axios.post('margin/all/pdf', form, {responseType: 'blob'});
    }

}
