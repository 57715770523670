import IMarginRow from '@/types/IMarginRow';
import ICurrencyDTO from '@/types/ICurrencyDTO';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

export default class ReportFactory {
    static toMargin(data: any): IMarginRow {
        return {
            sku: data.sku,
            month: data.month,
            orderNet: formatNumberWithSpaces(data.orderNet, 2),
            fbaFee: formatNumberWithSpaces(data.fbaFee, 2),
            referralFee: formatNumberWithSpaces(data.referralFee, 2),
            advertisingCost: formatNumberWithSpaces(data.advertisingCost, 2),
            productionCost: formatNumberWithSpaces(data.productionCost, 2),
            otherCost: formatNumberWithSpaces(data.otherCost, 2),
            marginNet: formatNumberWithSpaces(data.marginNet, 2),
            marginPercent: formatNumberWithSpaces(data.marginPercent, 2),
            monthsValues: !!data.monthsValues ? data.monthsValues.map((item:IMarginRow) => this.toMargin(item)) : [],
        };
    }

    static toCurrencyDTO(data: any): ICurrencyDTO {
        return {
            id: data.id,
            isoCode: data.isoCode,
            companyDefault: data.companyDefault,
        };
    }
}
