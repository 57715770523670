import IMarginFilters from '@/types/IMarginFilters';
import MarginRepository from '@/repositories/MarginRepository';
import MarginFactory from '@/factories/MarginFactory';
import FileService from '@/services/FileService';

export default class MarginService {

    static async getAll(filtres: IMarginFilters) {
        const {data} = await MarginRepository.getAll(filtres);

        return data.map((item: any) => MarginFactory.toMargin(item));
    }

    static async getForm() {
        const {data} = await MarginRepository.getForm();

        return {
            currencies: data.currencies.map((item: any) => MarginFactory.toCurrencyDTO(item)),
            marketplaces: data.marketplaces,
        };
    }

    static async downloadExcel(form: object) {
        const {data} = await MarginRepository.downloadExcel(form);

        const file = new FileService(data);
        file.downloadFile('margin');
    }

    static async downloadPdf(form: object) {
        const {data} = await MarginRepository.downloadPdf(form);

        const file = new FileService(data);
        file.downloadFile('margin');
    }

}
