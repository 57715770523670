var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data,"single-expand":true,"expanded":_vm.expanded,"options":_vm.options,"item-key":"sku","calculate-widths":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"item.marginPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.marginPercent)+" % ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0!important","padding-left":"6%!important"},attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.nestedHeaders.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":item.monthsValues,"options":_vm.nestedOptions,"hide-default-footer":"","item-key":"month"},scopedSlots:_vm._u([{key:"item.marginPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.marginPercent)+" % ")]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }