




































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import IMarginRow from '@/types/IMarginRow';

@Component({
  name: 'MarginTable',
  components: {},
})
export default class MarginTable extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  data!: IMarginRow[];

  private expanded: IMarginRow[] = [];
  private lastClicked: string = '';
  private options = {
    itemsPerPage: 50,
    sortBy: ["sku"],
  }

  private nestedOptions = {
    itemsPerPage: 12,
    sortBy: ["month"],
  }

  expandRow(value: IMarginRow) {
    if (this.lastClicked === value.sku) {
      this.expanded = [];
      this.lastClicked = '';
    } else {
      this.lastClicked = value.sku;
      this.expanded = value.monthsValues;
    }
  }

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_SKU',
      value: 'sku',
    },
    {
      text: 'SYSTEM_ORDER_NET',
      value: 'orderNet',
      sortable: false,
    },
    {
      text: 'SYSTEM_FBA_FEE',
      value: 'fbaFee',
      sortable: false,
    },
    {
      text: 'SYSTEM_REFERRAL_FEE',
      value: 'referralFee',
      sortable: false,
    },
    {
      text: 'SYSTEM_ADVERTISING_COST',
      value: 'advertisingCost',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_PRODUCTION_COST',
      value: 'productionCost',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_OTHER_COST',
      value: 'otherCost',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_NET',
      value: 'marginNet',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_PERCENT',
      value: 'marginPercent',
      sortable: false,
      width: 105
    },
  ];

  nestedHeaders: DataTableHeader[] = [
    {
      text: 'SYSTEM_DATE',
      value: 'month',
    },
    {
      text: 'SYSTEM_ORDER_NET',
      value: 'orderNet',
      sortable: false,
    },
    {
      text: 'SYSTEM_FBA_FEE',
      value: 'fbaFee',
      sortable: false,
    },
    {
      text: 'SYSTEM_REFERRAL_FEE',
      value: 'referralFee',
      sortable: false,
    },
    {
      text: 'SYSTEM_ADVERTISING_COST',
      value: 'advertisingCost',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_PRODUCTION_COST',
      value: 'productionCost',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_OTHER_COST',
      value: 'otherCost',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_NET',
      value: 'marginNet',
      sortable: false,
    },
    {
      text: 'SYSTEM_MARGIN_PERCENT',
      value: 'marginPercent',
      sortable: false,
    },
  ];

  @Emit('paginationChanged')
  paginationChanged(page: number) {
    return page;
  }
}
